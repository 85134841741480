import { CONFIG } from "@/Config/consts/CONFIG";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

export const app = initializeApp({
  ...CONFIG.firebaseConfig,
});

const region = CONFIG.firebaseConnectEmulator
  ? "us-central1"
  : CONFIG.firebaseRegion;

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, region);
export const storage = getStorage(app);
export const analytics =
  typeof window !== "undefined" && CONFIG.firebaseConfig.measurementId
    ? getAnalytics(app)
    : null;

/**
 * To use this you have to set NEXT_PUBLIC_CONNECT_FIREBASE_FUNCTIONS_EMULATOR=true in .env
 */
if (CONFIG.firebaseConnectEmulator) {
  const host = "localhost";

  connectFunctionsEmulator(functions, host, 5001);
  connectFirestoreEmulator(db, host, 8080);
  connectAuthEmulator(auth, `http://${host}:9099`);
  connectStorageEmulator(storage, host, 9199);
}
