"use client";

import { KawaseBlurFilter } from "pixi-filters";
import { Application, Ticker } from "pixi.js";
import {
  CanvasHTMLAttributes,
  DetailedHTMLProps,
  useLayoutEffect,
  useRef,
} from "react";
import { ColorPalette } from "./color-palette";
import { Orb } from "./orb";
import { cn } from "@/Common/utils";

interface Props
  extends Omit<
    DetailedHTMLProps<
      CanvasHTMLAttributes<HTMLCanvasElement>,
      HTMLCanvasElement
    >,
    "ref" | "height"
  > {
  className?: string;
  height?: number;
  /**
   * 0.0 (left) – 1.0 (right)
   */
  posX?: number;
  /**
   * 0.0 (top) – 1.0 (bottom)
   */
  posY?: number;
  baseHue?: number;
}

export function Orbs({ className, posX, posY, baseHue, ...props }: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const tickerRef = useRef<Ticker>();

  useLayoutEffect(() => {
    // Disable during tests.
    if (navigator.userAgent.includes("jsdom")) {
      return;
    }

    const app = new Application();

    async function init() {
      if (!canvasRef.current) {
        return;
      }
      await app.init({
        canvas: canvasRef.current,
        resizeTo: canvasRef.current,
        backgroundAlpha: 0,
      });
      app.stage.filters = [
        new KawaseBlurFilter({
          strength: 50,
          quality: 10,
          clamp: true,
        }),
      ];

      // Create color palette
      const colorPalette = new ColorPalette(baseHue);

      // Create orbs
      const orbs: Orb[] = [];

      for (let i = 0; i < 10; i++) {
        const orb = new Orb({
          fill: colorPalette.randomColor(),
          canvasRef,
          posX,
          posY,
        });
        app.stage.addChild(orb.graphics);
        orbs.push(orb);
      }

      // Animate!
      if (window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
        // Only render static orbs.
        orbs.forEach((orb) => {
          orb.update();
          orb.render();
        });
      } else {
        tickerRef.current = app.ticker.add(() => {
          orbs.forEach((orb) => {
            orb.update();
            orb.render();
          });
        });
      }
    }

    init().catch((error) => {
      console.error(error);
    });

    return () => {
      tickerRef.current?.stop();
      tickerRef.current?.destroy();
      app.stop?.();
    };
  }, [baseHue, posX, posY]);

  return (
    <canvas
      ref={canvasRef}
      className={cn(
        "fixed top-0 left-0 w-full h-full pointer-events-none z-0",
        className,
      )}
      {...props}
    />
  );
}
