import(/* webpackMode: "eager" */ "/home/runner/work/orcrist.org/orcrist.org/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/orcrist.org/orcrist.org/src/Common/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/orcrist.org/orcrist.org/node_modules/next/font/google/target.css?{\"path\":\"src/Common/consts/FONT.ts\",\"import\":\"Work_Sans\",\"arguments\":[{\"variable\":\"--font-family-sans\",\"subsets\":[\"latin\"]}],\"variableName\":\"FONT\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Orbs"] */ "/home/runner/work/orcrist.org/orcrist.org/src/Common/ui/orbs/orbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/orcrist.org/orcrist.org/src/Common/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnalyticsProvider"] */ "/home/runner/work/orcrist.org/orcrist.org/src/Firebase/views/AnalyticsProvider.tsx");
