"use client";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { logEvent } from "../helpers/logEvent";

export function AnalyticsProvider() {
  const pathname = usePathname();

  useEffect(() => {
    logEvent({
      event: "page_view",
      title: document.title,
      path: pathname,
      url: window.location.href,
    });
  }, [pathname]);

  return null;
}
