export const CONFIG = {
  firebaseRegion: process.env.NEXT_PUBLIC_FIREBASE_REGION as string,
  firebaseConnectEmulator: process.env.APP_FIREBASE_CONNECT_EMULATOR === "true",
  firebaseConfig: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY as string,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN as string,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET as string,
    messagingSenderId: process.env
      .NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID as string,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID as string,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID as string,
  },
  smtpIsDevelopment: process.env.SMTP_IS_DEVELOPMENT === "true",
  smtpHost: process.env.SMTP_HOST as string,
  smtpPort: parseInt(process.env.SMTP_PORT as string, 10),
  smtpSenderMail: process.env.SMTP_SENDER_MAIL as string,
  teamDeckPdfUrl: process.env.TEAM_DECK_PDF_URL as string,
};
